import React, { useState, useEffect } from "react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Helmet } from "react-helmet";
const Contact = () => {

  const [email, setEmail] = useState('');
  const [fullName, setfullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [WebsiteUrl, setWebsiteUrl] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [CompanyHeadquarter, setCompanyHeadquarter] = useState('');
  const [msg, setMessage] = useState('');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value); // Update selected option state
  };

  const [isChecked, setIsChecked] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const toggleMoreText = () => {
    setShowMore(!showMore);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled and have valid inputs
    if (
      fullName &&
      email &&
      email.includes('@') && // Basic email validation
      phoneNumber &&
      phoneNumber.length === 10 && // Basic phone validation (assuming 10 digits)
      CompanyName &&
      WebsiteUrl &&
      selectedOption &&
      CompanyHeadquarter &&
      msg &&
      isChecked
    ) {
      // Data to be sent to the API
      const data = {
        fullName,
        emailId: email,
        phoneNumber,
        companyName: CompanyName,
        websiteUrl: WebsiteUrl,
        employeeSize: selectedOption,
        companyHeadquarter: CompanyHeadquarter,
        message: msg,
      };

      // Send data to the API
      sendContactDetailsForm(data);
    } else {
      setErrorMessage(true);
      setSuccessMessage(false);

      setTimeout(() => {
        setErrorMessage(false);
        setSuccessMessage(false);
      }, 5000);
    }
  };


  const sendContactDetailsForm = async (data) => {
    try {
      const response = await fetch("https://demo.cxfirst.ai/contactDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("API response:", responseData);
        setSuccessMessage(true);
        setErrorMessage(false);
      } else {
        console.error("Error:", response.status);
        setErrorMessage(true);
        setSuccessMessage(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(true);
      setSuccessMessage(false);

      setTimeout(() => {
        setErrorMessage(false);
        setSuccessMessage(false);
      }, 5000);
    }
  };

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);


  // map code 
  useEffect(() => {
    // Initialize the map centered on the given location (Udaipur)
    const map = L.map('map').setView([24.5456, 73.7139], 13);

    // Set up the tile layer
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Create a red marker icon
    const redIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png", // Use the imported red icon
      // shadowUrl: markerShadow,
      iconSize: [25, 41], // size of the icon
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
      shadowSize: [41, 41]  // size of the shadow
    });

    // Add red marker at the specified location
    L.marker([24.5677, 73.7125], { icon: redIcon }).addTo(map)
      .bindPopup('55, "Sankalp Sector 8, Laxmi Nagar, Hiran Magri, Udaipur, Rajasthan 313002.<br>')
      .openPopup();

    // Clean up function to remove map on component unmount
    return () => {
      map.remove();
    };
  }, []);

  return (

    <div id="content" className="contact-page bg-light">
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="container">
        <div className="row text-start">
          <div className="col-lg-5 col-xl-4 ctext">
            <h1>Get in touch.</h1>
            <div className="cinfo">
              <p>
                Complete the form and we'll call you back to arrange a suitable
                time to discuss further.
              </p>

              <p>
                If you’re a current customer looking for support, please contact{" "}
                <a href="mailto:support@cxfirst.ai">support@cxfirst.ai</a>
              </p>
            </div>
            <div className="ctext mt">
              <h1 className="fs-4">Cxfirst AI LLP.</h1>
              <h1 className="fs-6">Powered By GLocal Travel Experiences Private Limited</h1>
              <p>55, "Sankalp Sector 8, Laxmi Nagar, Hiran Magri, Udaipur, Rajasthan 313002</p>
            </div>
            <div>
              <div id="map" style={{ height: '340px', width: '95%' }}></div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-8 cform-wrapper">
            <form id="frmcontactus" className="cform">
              <div className="row gy-3">
                <div className="col-12">
                  <label>
                    Full Name<span>*</span>
                  </label>
                  <input
                    maxLength=" 50"
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter your name"
                    value={fullName}
                    onChange={(e) => setfullName(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Email<span>*</span>
                  </label>
                  <input
                    maxLength="100"
                    type="text"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Phone Number<span>*</span>
                  </label>
                  <input
                    maxLength="10"
                    type="text"
                    name="mobile"
                    className="form-control"
                    placeholder="Enter phone no."
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Company Name<span>*</span>
                  </label>
                  <input
                    maxLength="150"
                    type="text"
                    name="company_name"
                    className="form-control"
                    placeholder="Enter company name"
                    value={CompanyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Website URL<span>*</span>
                  </label>
                  <input
                    maxLength="150"
                    type="text"
                    name="web_url"
                    className="form-control"
                    placeholder="Enter website URL"
                    value={WebsiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Employees<span>*</span>
                  </label>
                  <select name="no_of_employee" className="form-select" value={selectedOption}
                    onChange={handleOptionChange} >
                    <option value="">Company Size</option>
                    <option value="1-50">1-50</option>
                    <option value="51-100">51-100</option>
                    <option value="101-250">101-250</option>
                    <option value="251-500">251-500</option>
                    <option value="500+">500+</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label>
                    Company Headquarter<span>*</span>
                  </label>
                  <input
                    maxLength="150"
                    type="text"
                    name="designation"
                    className="form-control"
                    placeholder="Company location"
                    value={CompanyHeadquarter}
                    onChange={(e) => setCompanyHeadquarter(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <label>
                    Message<span>*</span>
                  </label>
                  <textarea
                    maxLength="1000"
                    id="msg"
                    name="msg"
                    className="form-control"
                    rows="3"
                    placeholder="Write your message"
                    value={msg}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <div id="counter" className="charlimit float-end"></div>
                </div>

                <div className="col-md-12 form-group">
                  <div className="form-check small agreebtn">
                    <input
                      className="form-check-input frm_check_box"
                      id="agree"
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="agree">
                      I agree to receive other communications from CxFirst.{' '}
                      <button type="button" className="moretxt_btn" onClick={toggleMoreText} style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', padding: 0 }}>
                        {showMore ? '[Less ' : '[More '}
                        <i className={`fa fa-angle-${showMore ? 'up' : 'down'}`}></i>
                      </button>
                    </label>

                    {showMore && (
                      <div className="agree-msg">
                        <p>
                          CxFirst is committed to protecting and respecting your privacy, and we’ll only use your personal
                          information to administer your account and to provide the products and services you requested from
                          us. From time to time, we would like to contact you about our products and services, as well as
                          other content that may be of interest to you. If you consent to us contacting you for this purpose,
                          please tick above to say how you would like us to contact you.
                        </p>

                        <p>
                          You can unsubscribe from these communications at any time. For more information on how to unsubscribe,
                          our privacy practices, and how we are committed to protecting and respecting your privacy, please
                          review our Privacy Policy.
                        </p>

                        <p>
                          By clicking submit below, you consent to allow CxFirst to store and process the personal information
                          submitted above to provide you the content requested.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {/* <input hidden="" name="contact_form" value="contact_form" />
                <input
                  hidden=""
                  name="Captcha_token"
                  id="Captcha_token"
                  className="Captcha_token"
                  value=""
                /> */}
                 {/*<div className="col-12">
                  <ReCAPTCHA
                    sitekey="YOUR_SITE_KEY" // Replace with your reCAPTCHA site key
                    onChange={(value) => setRecaptcha((prevData) => ({ ...prevData, captchaValue: value }))}
                  />
                </div>*/}
                <div className="col-12">
                  <button
                    id="btn_contact_form"
                    type="button"
                    className="btn btn-primary position-relative"
                    onClick={handleFormSubmit}
                  >
                    <span>Send Message</span>
                    <div className="loader loader_Send_Message">
                      <img src="assets/images/loader.svg" alt=".." />
                    </div>
                    <div className="success success_Send_Message">
                      <img src="assets/images/tick.svg" alt="✓" />
                    </div>
                    <div className="error error_Send_Message">
                      <img src="assets/images/alert.svg" alt="!" />
                    </div>
                  </button>
                </div>
              </div>
              {successMessage && (
                <div
                  // style={{display: 'none'}}
                  className="btn_contact_form_success msg-success"
                >
                  <img src="assets/images/thumb-up.svg" alt="Success" /> Thank you,
                  will get back to you shortly.
                </div>
              )}
              {errorMessage && (
                <div
                  // style={{display: 'none'}}
                  className="btn_contact_form_error msg-error"
                >
                  <img src="assets/images/alert2.svg" alt="Success" /> Please enter all required filds!
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Contact;


