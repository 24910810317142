import React, { useContext } from "react";
import Blogbanner from "./component/blogbanner";
import { AppContext } from "../AppContext";
import { Link } from "react-router-dom";

const Blogs = () => {

  const { blogs } = useContext(AppContext);

  return (

    <div>
      <Blogbanner />

      <div id="content">
        <div className="container">
          <ul id="bloglist" className="row text-start">
            {blogs.map((data, index) => {
              let date = data?.date.split(" ");
              return (
                <li className="col-md-6" key={index}>
                  <div className="bl_wrapper">
                    <Link to={data?.url}>
                      <img
                        src={data?.image}
                        alt="5 Key Elements of Delivering Exceptional Customer Service"
                      />
                      <div className="row text-start">
                        <div className="col-auto">
                          <div className="blogDate">
                            {date.map((data, index) => {
                              return index === 1 ? <span key={index}>{data}</span> : data;
                            })}
                            {/* Mar <span>28</span> 2023 */}
                          </div>
                        </div>
                        <div className="col">
                          <h2 className="blogTitle">
                            {data?.title}
                          </h2>
                          <p>
                            {data?.short_description}
                          </p>
                          <span className="btn btn-outline-primary">
                            Read More
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
