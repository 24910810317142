import React, { useContext, useEffect, useState } from 'react'
import Blogbanner from "./component/blogbanner";
import { AppContext } from '../AppContext';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BlogReadingPage = () => {
  const { blogId } = useParams();
  const { blogs } = useContext(AppContext);
  const location = useLocation();

  const [blogsDetails , setBlogsDetails] = useState({});
  const [dateDetails , setdateDetails] = useState();

  useEffect(() => {
    // Define an async function inside the useEffect
    const getBlogBySlug = async (blogId) => {
      return new Promise((resolve, reject) => {
        // Perform the search operation
        const blog = blogs.find(b => b.slug === blogId);
  
        if (blog) {
          resolve(blog); // Resolve the promise with the found blog
        } else {
          reject('Blog not found'); // Reject the promise if no blog matches
        }
      });
    };
  
    // Use the async function inside the useEffect
    const fetchBlog = async () => {
      try {
        const result = await getBlogBySlug(blogId);  // Call the async function
        const date = result?.date.split(" ");
        setBlogsDetails(result)
        setdateDetails(date)
        window.scrollTo(0, 0);  // Scroll to the top when the location changes
      } catch (error) {
        console.error(error);  // Handle the error if the blog is not found
      }
    };
  
    fetchBlog(); // Call the async function
  }, [location, blogId, blogs]);

  if (!blogsDetails) {
    return <div>Blog not found!</div>;
  }

  return (
    <div>
      <Helmet>
        <title>{blogsDetails?.title}</title>
      </Helmet>
      <Blogbanner />
      <div id="content">
        <div className="container text-start" id="blogDetail">
          <div className="row">
            <div className="col blogCol">
              <div className="row">
                <div className="col-auto">
                  <div className="blogDate">
                    {dateDetails?.map((data, index) => {
                      return index === 1 ? <span key={index}>{data}</span> : data;
                    })}
                  </div>
                </div>
                <div className="col align-self-center ps-0">
                  <h1 className="blogTitle">{blogsDetails?.title}</h1>
                </div>
              </div>

              <div className="text-center">
                <img src={blogsDetails?.image} alt="The Role of Artificial Intelligence in Enhancing Customer Service Quality" className="rounded-2" />
              </div>

              {blogsDetails?.content}

            </div>

            <div className="col-md-4 col-lg-3 d-none d-md-flex">
              <div className="card" id="aside_blist">
                <div className="card-header">All Blogs</div>
                <div className="card-body">
                  <ul>
                    {blogs?.map((data, index) => {
                      return (
                        <li key={index}>
                          <h6><a href={data?.url}>{data?.title}</a></h6>
                          <small>{data?.date}</small>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogReadingPage
